import React from 'react';
import Layout from '../components/layout';
import Container from '../components/atoms/Container/Container';
import FancyHeading from '../components/molecules/FancyHeading/FancyHeading';
import Pagination from '../components/pagination';
import { graphql } from 'gatsby';
import ReviewGroup from '../components/organisms/ReviewGroup/ReviewGroup';
import SEO from '../components/seo';
import IEBanner from '../components/iebanner';

export default function reviews({ data, pageContext }) {

  return (  
    <>
      <SEO title={data.page.pageBuilder.pageName} />
      <IEBanner />
      <Layout>
        <Container className="bg-brand-light-grey">
              <FancyHeading center="true" darkBg="">{data.page.pageBuilder.items[0].heading}</FancyHeading>
              <p className="font-lato w-3/5 mx-auto text-center text-brand-dark-grey text-lg mb-10">{data.page.pageBuilder.items[0].text}</p>
              <ReviewGroup skip={pageContext.skip} />
              <Pagination pageSize={parseInt(process.env.GATSBY_REVIEWS_PER_PAGE)} totalCount={data.reviewOrder.nodes[0].reviews.length} base="reviews"/>
          </Container>
      </Layout>
    </>
  );
}

export const data = graphql
  `
  query {
    reviewOrder: allSanityReviewOrderAndFeatured {
      nodes {
        reviews: reviewOrder {
          reviewerTitle
        }
      }
    }
    page: sanityLandingPage(slug: {current: {eq: "5kuao3nf7jteyzvy7lyws"}}) {
      pageBuilder {
        pageName
        items {
          ... on SanityReviewsSection {
            heading
            text
          }
        }
      }
    }
  }
  `
;