import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Review from '../Review/Review';

function ReviewGroup({skip}) {

  const data = useStaticQuery(
    graphql
    `
    query {
      reviewOrder: allSanityReviewOrderAndFeatured {
        nodes {
          reviews: reviewOrder {
            _rawSummary
            reviewerName
            reviewerTitle
            _rawFullReview
          }
        }
      }
    }
    `
  );

  skip = skip || 0;

  return(
    <div className="mb-8">
      {data.reviewOrder.nodes[0].reviews.slice(skip, Math.min(skip + parseInt(process.env.GATSBY_REVIEWS_PER_PAGE), data.reviewOrder.nodes[0].reviews.length)).map((review) => (
        <Review review={review} primary={false} />
      ))}
    </div>
  );
}

export default ReviewGroup;